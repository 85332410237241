/**
 * Form fields
 */
label {
  display: block;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 0.25em;
}

input[type='text'],
input[type='password'],
input[type='email'],
input[type='tel'],
input[type='number'],
input[type='search'],
input[type='url'],
select,
textarea {
  background: 0;
  border: 1px solid $color-border;
  border-radius: 3px;
  box-shadow: none;
  box-sizing: border-box;
  color: $color-text;
  display: block;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  max-width: 100%;
  padding: 0.625em 0.625em;
  width: 100%;

  &:focus {
    outline: 0;
  }
}

.form-group {
  margin-bottom: 1.125rem;
}

.form-submit {
  margin-top: 1.875rem;
}

.form-select-wrap {
  font-size: 1em;
  position: relative;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 25px;

    &:focus {
      outline: 0;
    }
  }

  &:before,
  &:after {
    border: solid transparent;
    border-width: 0.3125em;
    box-sizing: border-box;
    content: '';
    position: absolute;
    right: 10px;
    top: 50%;
    z-index: 1;
  }

  &:before {
    border-top-color: $color-text;
    margin-top: 0.125em;
  }

  &:after {
    border-bottom-color: $color-text;
    margin-top: -0.75em;
  }
}

// UNSUBSCRIBE
.form-checkbox {
  padding-left: 1.5em;
  position: relative;

  input[type='checkbox'] {
    height: 1.5em;
    left: 0;
    position: absolute;
    top: 0;
  }

  label {
    font-weight: normal;
  }
}

// UNSUBSCRIBE - OTHER
#reason_other {
  opacity: 0.5;
  pointer-events: none;
  margin-left: 30px;
  margin-top: 5px;
  width: 70%;
}

#other:checked ~ #reason_other {
  opacity: 1;
  pointer-events: auto;
}

/**
 * Content Sections
 */
.section {
  margin-bottom: 3.75rem;

  &:first-child {
    padding-top: 3.75rem;
  }

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 3.75em;
  }

  .container + .container {
    margin-top: 2.25rem;
  }
}

.bg-blue,
.bg-gray,
.has-bg {
  padding-bottom: 3.75em;
  padding-top: 3.75em;
}

.bg-blue {
  background-color: $color-bg-alt;
}

.bg-gray {
  background-color: $color-light-gray;
}

.section__media,
.section__body {
  position: relative;
}

.section__actions {
  a {
    &:not(.btn) {
      font-weight: 700;
    }
  }
}

.hero,
.section--features {
  .cell {
    max-width: $width-md;

    &:only-child {
      flex: 0 0 100%;
    }
  }
}

.hero {
  &:first-child {
    &:not(.section--padding) {
      margin-bottom: 2.25rem;
      padding-bottom: 0;
    }
  }
}

.section--features {
  .flex {
    margin-bottom: 3.75rem;
  }
}

.section--team,
.section--posts {
  .cell {
    margin-bottom: 1.5rem;
    max-width: $width-sm;
  }
}

.section--team {
  .card__media {
    margin-top: auto;
    padding-top: 100%;
    position: relative;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}

.section--cta {
  .bg-blue,
  .bg-gray {
    border-radius: 3px;
  }
}

@include media-breakpoint-up(md) {
  .section__media {
    &--right {
      order: 1;
    }
  }
}

@include media-breakpoint-down(md) {
  .section__media {
    margin-bottom: 1.5rem;
  }

  .section--team,
  .section--posts {
    .flex {
      justify-content: center;
    }
  }
}

// Font rendering
@mixin fontSmooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Clearing floats
@mixin clearfix() {
  &:after {
    clear: both;
  }

  &:before,
  &:after {
    content: '';
    display: table;
  }
}

// Screen reader text
@mixin sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

// Breakpoints
@mixin media-breakpoint-up($name, $breakpoints: $media-breakpoints) {
  @if map-has-key($breakpoints, $name) {
    $min: map-get($breakpoints, $name) + 1;
    @media (min-width: $min) {
      @content;
    }
  }
}

@mixin media-breakpoint-down($name, $breakpoints: $media-breakpoints) {
  @if map-has-key($breakpoints, $name) {
    $max: map-get($breakpoints, $name);
    @media (max-width: $max) {
      @content;
    }
  }
}

/**
 * Helper Classes
 */
.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}

.container--md {
  max-width: $width-md;
}

.container--lg {
  max-width: $width-lg;
}

/* Headings */
.h1 {
  @extend h1;
}

.h2 {
  @extend h2;
}

.h3 {
  @extend h3;
}

.h4 {
  @extend h4;
}

.h5 {
  @extend h5;
}

.h6 {
  @extend h6;
}

/* Text alignment */
.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

/* Background image */
.bg-image {
  position: relative;
}

.bg-image__image {
  background-position: center center;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

/* Text meant only for screen readers */
.sr-only {
  @include sr-only();
}

/* Clear floats */
.clear {
  @include clearfix();
}

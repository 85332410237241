/**
 * Posts and Plain Pages
 */
.post,
.page {
  padding-bottom: 3.75rem;
  // padding-top: 3.75rem;
}

.post__image,
.post__header,
.page__header {
  margin: 0 0 1.5rem;
}

.post__title,
.page__title {
  margin-top: 0;
}

.post__meta,
.post__footer {
  color: $color-meta;
  font-size: 0.875rem;
}

.post__meta {
  margin-bottom: 0.75rem;
}

.post__footer {
  margin-top: 2.25rem;

  span {
    display: inline-block;
    margin-right: 0.5rem;

    &:before {
      content: '\0023';
    }
  }
}

/**
 * Site Layout
 */
.site {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.site-content {
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%;
}

/*
 * Scrollbar
 */
body {
  scrollbar-color: #ffc702 #2e2e2e;
  scrollbar-width: thin;
}

/* Firefox */
html {
  scrollbar-color: #ecc53c #2e2e2e;
  scrollbar-width: thin;
}

/* WebKit and Chromiums */
::-webkit-scrollbar {
  background-color: #2e2e2e;
  width: 16px;
}

::-webkit-scrollbar-thumb {
  background: #ecc53c;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: #2e2e2e;
}

// Source: https://codepen.io/stoumann/pen/mdEQdqy

[data-after-text],
[data-before-text] {
  --badge-offset-x: calc(0px - var(--badge-size) / 3);
  --badge-offset-y: calc(0px - var(--badge-size) / 3);
  --badge-size: 1.5rem;
  --circle-size: 2rem;
  --dot-offset: 0.5rem;
  --dot-size: 0.5rem;

  --b: initial;
  --bgc: hsl(195, 100%, 30%);
  --bdrs: 0;
  --c: hsl(195, 100%, 99%);
  --d: inline-flex;
  --fz: 0.625rem;
  --fw: 700;
  --h: auto;
  --l: initial;
  --m: 0.4rem;
  --p: 0;
  --pos: static;
  --r: initial;
  --t: initial;
  --tt: uppercase;
  --w: initial;

  position: relative;
}

[data-after-text]:not([data-after-text=''])::after {
  content: attr(data-after-text);
}
[data-before-text]:not([data-before-text=''])::before {
  content: attr(data-before-text);
}

[data-after-text]:not([data-after-text=''])::after,
[data-before-text]:not([data-before-text=''])::before {
  align-items: center;
  background: var(--bgc);
  border-radius: var(--bdrs);
  bottom: var(--b);
  box-shadow: var(--bxsh);
  box-sizing: border-box;
  color: var(--c);
  display: var(--d);
  font-size: var(--fz);
  font-weight: var(--fw);
  height: var(--h);
  justify-content: center;
  left: var(--l);
  padding: var(--p);
  position: var(--pos);
  right: var(--r);
  text-decoration: none;
  text-transform: var(--tt);
  top: var(--t);
  width: var(--w);
}

/* MODIFIERS */
[data-after-type*='badge']::after,
[data-before-type*='badge']::before {
  --bdrs: var(--badge-size);
  --bxsh: 0 0 0 2px rgba(255, 255, 255, 0.5);
  --h: var(--badge-size);
  --p: 0;
  --pos: absolute;
  --w: var(--badge-size);
}
[data-after-type*='circle'],
[data-before-type*='circle'] {
  align-items: center;
  display: flex;
}
[data-after-type*='circle']::after,
[data-before-type*='circle']::before {
  --bdrs: 50%;
  --fw: 400;
  --h: var(--circle-size);
  // --pos: relative;
  // --t: -0.75em;
  --tt: initial;
  --w: var(--circle-size);
}
[data-after-type*='circle']::after,
[data-after-type*='pill']::after {
  margin-inline-start: 1ch;
}
[data-before-type*='circle']::before,
[data-before-type*='dot']::before,
[data-before-type*='pill']::before {
  margin-inline-end: 1ch;
}
[data-after-type*='dot']::after,
[data-before-type*='dot']::before {
  --bdrs: 50%;
  --d: inline-block;
  --fz: 50%;
  --h: var(--dot-size);
  --p: 0;
  --pos: relative;
  --t: -1px;
  --w: var(--dot-size);
}
[data-after-type*='dot']::after,
[data-before-type*='dot']::before {
  content: '' !important;
}
[data-after-type*='pill']::after,
[data-before-type*='pil']::before {
  --bdrs: 1rem;
  --p: 0.25rem 0.75rem;
  --pos: relative;
  --t: -1px;
}

/* COLORS */
[data-after-type*='blue']::after,
[data-before-type*='blue']::before {
  --bgc: #007acc;
}
[data-after-type*='gray']::after,
[data-before-type*='gray']::before {
  --bgc: #354a5d;
  --c: #fff;
}
[data-after-type*='green']::after,
[data-before-type*='green']::before {
  --bgc: #04844b;
}
[data-after-type*='lightgray']::after,
[data-before-type*='lightgray']::before {
  --bgc: #ecebea;
  --c: #080707;
}
[data-after-type*='yellow']::after,
[data-before-type*='yellow']::before {
  --bgc: #ecc53c;
  --c: #080707;
}

[data-after-type*='red']::after,
[data-before-type*='red']::before {
  --bgc: #c23934;
}

/* POSITION */
[data-after-type*='top']::after,
[data-before-type*='top']::before {
  --b: auto;
  --pos: absolute;
  --t: var(--dot-offset);
}
[data-after-type*='right']::after,
[data-before-type*='right']::before {
  --l: auto;
  --pos: absolute;
  --r: var(--dot-offset);
}
[data-after-type*='bottom']::after,
[data-before-type*='bottom']::before {
  --b: var(--dot-offset);
  --pos: absolute;
  --t: auto;
}
[data-after-type*='left']::after,
[data-before-type*='left']::before {
  --pos: absolute;
  --r: auto;
  --l: var(--dot-offset);
}
[data-after-type*='badge'][data-after-type*='top']::after,
[data-before-type*='badge'][data-before-type*='top']::before {
  --m: 0;
  --t: var(--badge-offset-y);
}
[data-after-type*='badge'][data-after-type*='right']::after,
[data-before-type*='badge'][data-before-type*='right']::before {
  --m: 0;
  --r: var(--badge-offset-x);
}
[data-after-type*='badge'][data-after-type*='bottom']::after,
[data-before-type*='badge'][data-before-type*='bottom']::before {
  --b: var(--badge-offset-y);
  --m: 0;
}
[data-after-type*='badge'][data-after-type*='left']::after,
[data-before-type*='badge'][data-before-type*='left']::before {
  --l: var(--badge-offset-x);
  --m: 0;
}

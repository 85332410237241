// Colors
// Base
$color-text: #536171 !default;
$color-heading: #282f36 !default;
$color-meta: #8594a5 !default;
$color-bg: #fff !default;
$color-bg-alt: #354a5d !default;
$color-bg-overlay: rgba(245, 246, 247, 0.8) !default;
$color-border: #e1e4e9 !default;
$color-light-gray: #2e2e2e;
$color-yellow-on-white: #ffc702;

// Links
$color-link: #354a5d !default;

// Blockquotes
$color-blockquote: #282f36 !default;
$color-blockquote-border: #ecc53c !default;

// Buttons
$color-btn: #fff !default;
$color-btn-bg: #28aaff !default;
$color-btn-border: #28aaff !default;
$color-btn-alt: #fff !default;
$color-btn-alt-bg: #354a5d !default;
$color-btn-alt-border: #354a5d !default;
$color-btn-clear: #282f36 !default;

// Navigation
$color-nav-link: #536171 !default;
$color-nav-link-hover: #ffc400 !default;
$color-nav-link-active: #ffc400 !default;

// Inverse (light text on dark background)
$color-inverse-text: #f5f6f7 !default;
$color-inverse-heading: #fff !default;
$color-inverse-bg: #005bc4 !default;
$color-inverse-link: #f0c330 !default;
$color-inverse-btn: #fff !default;
$color-inverse-btn-bg: #28aaff !default;
$color-inverse-btn-border: #28aaff !default;
$color-inverse-btn-alt: #28aaff !default;
$color-inverse-btn-alt-bg: #fff !default;
$color-inverse-btn-alt-border: #fff !default;
$color-inverse-btn-clear: #fff !default;

// Shadows
$box-shadow: 0 0 1em rgba(0, 0, 0, 0.08) !default;

// Typography
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
  Helvetica, sans-serif;
$font-family-monospace: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
$font-family-base: $font-family-sans-serif;

// Widths
$width-xs: 360px !default;
$width-sm: 460px !default;
$width-md: 740px !default;
$width-lg: 1140px !default;

// Breakpoints
$media-breakpoints: (
  xs: 480px,
  sm: 640px,
  md: 800px,
  lg: 960px,
  xl: 1200px,
) !default;

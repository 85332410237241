/**
 * Icons
 */
.icon {
  color: inherit;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  height: 1em;
  line-height: 1;
  width: 1em;
}

#form_embed_signup form {
  text-align: center;
  padding: 10px 0 10px 0;
}

.mc-field-group {
  display: flex;
  flex-direction: column; /* Arrange label and input vertically */
  align-items: flex-start; /* Align items to the left */
  margin-bottom: 15px;
}

// LABELS
#form_embed_signup label {
  display: block;
  font-size: 16px;
  padding-bottom: 10px;
  font-weight: bold;
}

.mc-field-group label {
  text-align: left;
  margin-bottom: 5px;
  padding-bottom: 0px !important;
}

// INPUTS
#form_embed_signup input.email,
#form_embed_signup input.company,
#form_embed_signup input.adtitle,
#form_embed_signup input.adcta,
#form_embed_signup input.adlink,
#form_embed_signup input.issue_date {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, Verdana, sans-serif;
  font-size: 15px;
  border: 1px solid #abb0b2;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: #343434;
  background-color: #fff;
  box-sizing: border-box;
  height: 32px;
  padding: 0px 0.4em;
  display: inline-block;
  margin: 0;
  width: 350px;
  vertical-align: top;
}

#form_embed_signup textarea {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, Verdana, sans-serif;
  font-size: 15px;
  border: 1px solid #abb0b2;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: #343434;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0px 0.4em;
  display: inline-block;
  margin: 0;
  width: 350px;
  vertical-align: top;
}

.form-long {
  width: 80% !important;
}

#form_embed_signup .clear {
  display: block;
  width: 100%;
}

// SIGNUP BUTTON
#form_embed_signup .button-clear {
  display: inline-block;
} /* positions button horizontally in line with input */
#form_embed_signup .button {
  font-size: 13px;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  letter-spacing: 0.03em;
  color: #fff;
  background-color: #ecc53c;
  color: #282f36;
  box-sizing: border-box;
  height: 32px;
  line-height: 32px;
  padding: 0 18px;
  display: inline-block;
  margin: 0;
  transition: all 0.23s ease-in-out 0s;
}
#form_embed_signup .button:hover {
  background-color: #777;
  cursor: pointer;
}
#form_embed_signup div#mce-responses {
  float: left;
  top: -1.4em;
  padding-top: 10px;
  overflow: hidden;
  width: 90%;
  margin: 0 5%;
  clear: both;
}
#form_embed_signup div.response {
  margin: 1em 0;
  padding: 1em 0.5em 0.5em 0;
  font-weight: bold;
  float: left;
  top: -1.5em;
  z-index: 1;
  width: 80%;
}
#form_embed_signup #mce-error-response {
  display: none;
}
#form_embed_signup #mce-success-response {
  display: none;
}
#form_embed_signup label.error {
  display: block;
  float: none;
  width: auto;
  margin-left: 1.05em;
  text-align: left;
  padding: 0.5em 0;
}
@media (max-width: 768px) {
  #form_embed_signup input.email {
    width: 100%;
    margin-bottom: 5px;
  }
  #form_embed_signup .clear {
    display: block;
    width: 100%;
  }
  #form_embed_signup .button {
    width: 100%;
    margin: 0;
  }
}

// RADIO BUTTONS
input:where([type='radio']) {
  -webkit-appearance: none;
  appearance: none;
  width: 22px;
  height: 22px;
  margin: calc(0.75em - 11px) 0.25rem 0 0;
  vertical-align: top;
  border: 2px solid $color-meta;
  border-radius: 4px;
  background: #fff no-repeat center center;
}

input[type='radio'] {
  border-radius: 50%;
}

input:where([type='radio']):where(:active:not(:disabled), :focus) {
  border-color: $color-bg-alt;
  outline: none;
}

input:where([type='radio']):disabled {
  background: #eee;
}

input[type='radio']:checked {
  background: $color-bg-alt;
}

.radio-label {
  font-weight: 500 !important;
}

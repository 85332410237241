/**
 * Tables
 */
table {
  border-bottom: 1px solid $color-border;
  border-collapse: collapse;
  border-spacing: 0;
  line-height: 1.5;
  margin: 0;
  max-width: 100%;
  text-align: left;
  width: 100%;
}

caption {
  color: $color-meta;
  font-size: 0.875em;
  font-style: italic;
  margin-bottom: 0.75rem;
  text-align: left;
}

th,
td {
  border-top: 1px solid $color-border;
  padding: 0.5em 5px;
}

th {
  color: $color-heading;
  font-weight: 700;
}

:not(.responsive-table) > table {
  margin: 0 0 1.5rem;
}

.responsive-table {
  display: block;
  margin: 0 0 1.5rem;
  overflow-x: auto;
  width: 100%;
}

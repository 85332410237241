.tl {
  line-height: 1.3em;
}

.history-tl-container {
  margin: auto;
  display: block;
  position: relative;
}

.history-tl-container ul.tl {
  margin-top: 10px;
  padding: 0;
  display: inline-block;
}

.history-tl-container ul.tl li {
  list-style: none;
  margin: auto;
  margin-left: 50px;
  min-height: 50px;
  /*background: rgba(255,255,0,0.1);*/
  border-left: 1px dashed $color-link;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 20px;
  padding-left: 30px;
  position: relative;
}
.history-tl-container ul.tl li:last-child {
  border-left: 0;
  padding-bottom: 0px;
}
.history-tl-container ul.tl li::before {
  position: absolute;
  left: -18px;
  top: -5px;
  content: ' ';
  border: 8px solid rgba(255, 255, 255, 0.74);
  border-radius: 500%;
  background: $color-link;
  height: 20px;
  width: 20px;
  transition: all 500ms ease-in-out;
}
.history-tl-container ul.tl li:hover::before {
  border-color: $color-link;
  transition: all 1000ms ease-in-out;
}
ul.tl li .item-title {
}
ul.tl li .item-detail {
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
}
ul.tl li .timestamp {
  color: #8d8d8d;
  position: absolute;
  width: 100px;
  left: -50%;
  text-align: right;
  font-size: 12px;
}

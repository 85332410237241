/**
 * Cards
 */
.card {
  background-color: $color-bg;
  border: 1px solid $color-border;
  border-radius: 3px;
  box-shadow: $box-shadow;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.cell {
  .card {
    height: 100%;
  }
}

.card__media {
  img {
    display: block;
    width: 100%;
  }

  &:only-child {
    img {
      border-radius: 3px;
    }
  }

  &--top {
    img {
      border-radius: 3px 3px 0 0;
    }
  }

  &--bottom {
    order: 1;

    img {
      border-radius: 0 0 3px 3px;
    }
  }
}

.card__body {
  flex-grow: 1;
  padding: 1.5rem;
}

.card__header,
.card__meta,
.card__footer {
  margin-bottom: 1.125rem;
}

.card__title {
  margin-top: 0;
}

.card__meta,
.card__footer {
  color: $color-meta;
  font-size: 0.875em;
  line-height: 1.5;
}

.card__copy {
  & > *:last-child {
    margin-bottom: 1.125rem;
  }
}

/**
 * Grids
 */
.flex {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.flex--middle {
  align-items: center;
}

.flex--center {
  justify-content: center;
}

.cell {
  box-sizing: border-box;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  width: 100%;
}

.flex--col-2 {
  .cell {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.flex--col-3 {
  .cell {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@include media-breakpoint-down(md) {
  .flex--col-2,
  .flex--col-3 {
    .cell {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

/*
 * SPONSORS
 */
#sponsor-sample {
  width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.sponsors-out-container {
  display: grid;
}

.sponsors-container {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 33% 33% 33%;

  align-self: center;
  justify-self: center;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    display: block;
  }
}

.sponsor-item > a > img {
  display: block;
  margin: auto;
}

.sponsors-badge {
  max-width: 200px;
  max-height: 100px;

  vertical-align: middle;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

.sponsors-link {
  text-decoration: none;
}

.justify {
  text-align: justify;
}

/*
 * Past issues
 */
.past-list-table {
  margin: auto;
  align-self: center;
  td {
    border: 0 !important;
    padding-bottom: 5px !important;
  }
}

.past-list-date {
  text-align: center;
}

/*
 * FEATURES
 */
.feature-container {
  margin: auto;
}

.card-group {
  display: flex;
}

.card {
  padding: 10px;
  max-width: 18rem;
  text-align: center;
  margin: 0 auto;
  float: none;
  margin-bottom: 10px;
}

.card-title {
  padding-top: 10px;
  color: $color-link;
  font-size: xx-large;
  margin-bottom: 2px;
}

.card-icon {
  font-size: xx-large;
  margin: 0px;
}

.card-text {
  margin-top: 2px;
}

@media screen and (max-width: 600px) {
  .card-group {
    display: block;
  }
}

.center-text {
  text-align: center;
}

.small-image {
  width: 300px;
}

.highlight-yellow {
  background-color: $color-blockquote-border;
}

.highlight-yellow-text {
  color: $color-blockquote-border;
}

.highlight-yellow-text-dark {
  color: $color-yellow-on-white;
}

.highlight-bold {
  font-weight: bold;
}

/*
 * RESERVATIONS
 */
#table-reservations-past,
#table-reservations-next {
  text-align: center;
}

#table-reservations-past > thead > tr,
#table-reservations-next > thead > tr {
  background-color: #d3d3d3;
}

.reservation-booked {
  background-color: #383d419e;
  color: white;
}

.reservation-free {
  text-align: center;
  background-color: #b1dfbb;
  color: black;
}

.reservation-pending {
  background-color: antiquewhite;
}

/*
 * TESTIMONIALS
 */
.twitter {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 45% 45%;
  align-items: start;
  justify-content: space-evenly;

  @media screen and (max-width: 600px) {
    display: block;
  }
}

/*
 * HEADER TITLES
 */
.hero_title {
  margin-bottom: 0px;
}

.hero_subtitle {
  margin-top: 3px;
  margin-bottom: 30px;
  color: $color-blockquote-border !important;
  font-style: italic;
}

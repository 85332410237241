/**
 * Base Styles
 */
html {
  font-family: $font-family-base;
  font-size: 100%;
}

body {
  background-color: $color-bg;
  color: $color-text;
  line-height: 1.625;
  text-rendering: optimizeLegibility;
  @include fontSmooth();
}

a {
  color: $color-link;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-heading;
  font-weight: 700;
  line-height: 1.2;
  margin: 2.25rem 0 1.125rem;

  &:first-child {
    margin-top: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

h1 {
  font-size: 2.625em;
}

h2 {
  font-size: 2.25em;
}

h3 {
  font-size: 1.875em;
}

h4 {
  font-size: 1.5em;
}

h5 {
  font-size: 1.3125em;
}

h6 {
  font-size: 1.125em;
}

blockquote {
  border-left: 3px solid $color-blockquote-border;
  color: $color-blockquote;
  font-size: 1.25em;
  font-style: italic;
  line-height: 1.5;
  margin: 2.25rem 0;
  padding: 0 1.2em;

  &:first-child {
    margin-top: 0;
  }
}

p {
  // margin: 0 0 1.5rem;
}

ul,
ol {
  margin: 0 0 1.5rem;
  padding: 0 0 0 1.25rem;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li > ul,
li > ol {
  margin-bottom: 0;
}

hr {
  background-color: $color-border;
  border: 0;
  height: 1px;
  margin: 2.25rem 0;

  &:first-child {
    margin-top: 0;
  }
}

/* Media */
embed,
iframe,
object,
video {
  max-width: 100%;
}

img {
  height: auto;
  max-width: 100%;
}

figure {
  margin: 0;
}

@include media-breakpoint-down(sm) {
  h1 {
    font-size: 2.25em;
  }

  h2 {
    font-size: 1.875em;
  }

  h3 {
    font-size: 1.5em;
  }

  h4 {
    font-size: 1.3125em;
  }

  h5 {
    font-size: 1.125em;
  }

  h6 {
    font-size: 1em;
  }
}

/**
 * Styles for dark backgrounds
 */
.inverse {
  color: $color-inverse-text;

  a {
    color: $color-inverse-link;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color-inverse-heading;
  }

  .btn {
    background-color: $color-inverse-btn-bg;
    border-color: $color-inverse-btn-border;
    color: $color-inverse-btn;
  }

  .btn--secondary {
    background-color: $color-inverse-btn-alt-bg;
    border-color: $color-inverse-btn-alt-border;
    color: $color-inverse-btn-alt;
  }

  .btn--clear {
    background: 0;
    color: $color-inverse-btn-clear;
  }

  li {
    color: $color-inverse-text;
  }
}

/**
 * Button Styles
 */
button,
input[type='submit'],
input[type='button'],
input[type='reset'],
.btn {
  background-color: $color-btn-bg;
  border: 2px solid $color-btn-border;
  border-radius: 5px;
  box-sizing: border-box;
  color: $color-btn;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  font-weight: 700;
  line-height: 1.25;
  padding: 0.625em 1.5em;
  text-align: center;
  text-decoration: none;
  transition: 0.3s ease;
  vertical-align: middle;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.8;
    outline: 0;
  }
}

.btn--secondary {
  background-color: $color-btn-alt-bg;
  border-color: $color-btn-alt-border;
  color: $color-btn-alt;
}

.btn--dark {
  background-color: $color-light-gray;
  border-color: $color-light-gray;
}

.btn--icon {
  align-items: center;
  display: inline-flex;
  font-size: 1.5em;
  height: 1.25em;
  justify-content: center;
  line-height: 1.25em;
  padding: 0;
  position: relative;
  width: 1.25em;
}

.btn--clear {
  background: 0;
  border: 0;
  color: $color-btn-clear;

  &:hover,
  &:focus,
  &:active {
    opacity: 1;
  }
}

.btn-group {
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  a,
  .btn {
    margin: 0 1.125em 1.125em 0;
  }
}

.align-center {
  .btn-group {
    justify-content: center;

    a,
    .btn {
      margin-left: 0.5625em;
      margin-right: 0.5625em;
    }
  }
}

.align-right {
  .btn-group {
    justify-content: flex-end;

    a,
    .btn {
      margin-left: 1.125em;
      margin-right: 0;
    }
  }
}

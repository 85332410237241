#accordion {
  background-color: $color-light-gray;
  border: none;
}

#accordion h3 {
  background-color: #2c2c2c;
  border: none;
  color: #ffffff;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  text-align: left;
}

#accordion h3:hover {
  background-color: #454545;
}

#accordion div {
  background-color: $color-bg;
  border-top: 1px solid #2c2c2c;
  padding: 10px;
  color: $color-text;
}
